import React, { Component } from "react";
import {
    withRouter
} from "react-router-dom";
import ClientTemplateAPI from "../../swagger-client/ClientTemplatesAPI";
import ClientScenariiApi from "../../swagger-client/ClientScenarii";
import { Card, Col, Row, InputGroup } from "react-bootstrap";

class SaveTemplate extends Component {
    clientApi = new ClientTemplateAPI();
    clientScenariiApi = new ClientScenariiApi();
    cursorposition = 0;

    state = {
        template: {
            id: undefined,
            title: "",
            body: "",
            category: "",
            scenario: {},
            senderMail: "",
            messageTemplateCategory: {
                showButton: false,
                allowSignature: false
            },
            signature: undefined
        },
        categories: [],
        signatures: [],
        scenarii: []
    };

    componentDidMount() {
        var { id, categoryId, scenarioId } = this.props.match.params;

        if (id !== undefined && id > 0) {
            this.clientApi.retrieveMessageTemplate(id).then((res) => {
                this.setState({ template: res.data })
            })
        }

        this.clientApi.retrieveCategories().then((res) => {
            this.setState({ categories: res.data })
        })

        this.clientApi.retrieveSignatures().then((res => {
            this.setState({ signatures: res.data })
        }))

        this.clientScenariiApi.retrieveScenarii()
            .then(res => {
                this.setState({ scenarii: res.data })
            })
            .catch(error => {
                alert(error.message)
            })

        if (categoryId !== undefined) {
            var actualState = this.state
            actualState.template.messageTemplateCategory.id = categoryId
            this.setState(actualState)
        }

        if (scenarioId !== undefined) {
            var actualState = this.state
            actualState.template.scenario.id = scenarioId
            this.setState(actualState)
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.clientApi.saveMessageTemplate(this.state.template)
            .then((res) => {
                this.props.history.push("/templates/");
            }).catch((errors) => {
                alert("Un message existe déjà pour ce couple catégorie/scénario");
            });
    }

    handleTitle = (event) => {
        var template = this.state.template
        template.title = event.target.value
        this.setState({ template: template });
    }

    handleBody = (event) => {
        this.cursorposition = event.target.selectionStart;
        var template = this.state.template
        template.body = event.target.value
        this.setState({ template: template });
    }

    handleCategory = (event) => {
        var template = this.state.template
        this.state.categories.forEach(category => {
            if (category.id === parseInt(event.target.value)) {
                template.messageTemplateCategory = category
                if (!category.allowSignature) {
                    template.signature = undefined
                }
            }
        })
        this.setState({ template: template });

    }

    handleSignature = (event) => {
        var template = this.state.template
        if (event.target.value === "") {
            template.signature = undefined
        }
        else {
            this.state.signatures.forEach(signatureToCheck => {
                if (signatureToCheck.id === parseInt(event.target.value)) {
                    template.signature = signatureToCheck
                }
            })
        }

        this.setState({ template: template })
    }

    handleSender = (event) => {
        var template = this.state.template
        template.senderMail = event.target.value
        this.setState({ template: template })
    }

    handleScenario = (event) => {
        var template = this.state.template
        if (event.target.value === "") {
            template.scenario = undefined
        }
        else {
            this.state.scenarii.forEach(scenarioToCheck => {
                if (scenarioToCheck.id === parseInt(event.target.value)) {
                    template.scenario = scenarioToCheck
                }
            })
        }

        this.setState({ template: template })
    }

    sendMailTest = (event) => {

        this.state.template.bodyHTML =
            "<div>"
                .concat(this.state.template.body.replaceAll("\n", "<br />"))
                .concat("</div>");

        this.clientApi.sendMessageTest(this.state.template).then(res => {
            alert("Le mail est parti")
        })
            .catch(error => {
                alert("ERREUR : ".concat(error.message))
            })
    }

    addElementToTextarea(element) {
        var output = [this.state.template.body.slice(0, this.cursorposition), element, this.state.template.body.slice(this.cursorposition)].join('');
        var template = this.state.template
        template.body = output
        this.setState({ template: template });
        this.cursorposition += element.length
    }

    dismissModal = () => {
        this.setState({ modal: { show: false, message: "" } })
    }

    render() {

        var dynamicButton = this.state.template.messageTemplateCategory.showButton ?

            <div className="mb-3">
                <Row>
                    <div className="col-3 ">
                        <div className="input-group">
                            <button type="button" className="btn btn-success" onClick={() => this.addElementToTextarea("[CLIENT_ID]")} >N° Client</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[NOM]")} >Nom</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[PRENOM]")} >Prenom</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[EMAIL]")} >Mail</button>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="input-group">
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[NUM_DEMANDE]")} >Numéro de demande</button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="input-group">
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[BIKE_MRQ]")} >Marque</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[BIKE_MOD]")} >Modèle</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[BIKE_COL]")} >Couleur</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[BIKE_GEN]")} >Genre</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[BIKE_ELE]")} >Electrique</button>
                        </div>
                    </div>
                </Row>
                <Row>
                    <Col>
                        Pièces Jointes :
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[PJ_DEVIS]")} >Devis</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[PJ_VELO]")} >Votre vélo</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[PJ_ZOOMS]")} >Zooms</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[PJ_REPARE]")} >Vélo réparé</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[PJ_VALDEV]")} >Validation Devis</button>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[NBS_PHOTO]")}>Lien photo</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[GGL_AVIS]")}>Avis Google</button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Éléments de facturation
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <InputGroup>
                            <button type="button" className="btn btn-warning" onClick={() => this.addElementToTextarea("[DEVIS_TOTAL]")} >Montant devis</button>
                            <button type="button" className="btn btn-warning" onClick={() => this.addElementToTextarea("[DEVIS_VALTOT]")} >Montant devis validé</button>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup>
                            <button type="button" className="btn btn-success" onClick={() => this.addElementToTextarea("[FACT_NUM]")} >N° Facture</button>
                            <button type="button" className="btn btn-success" onClick={() => this.addElementToTextarea("[ENC_DATE]")} >Date encaissement</button>
                            <button type="button" className="btn btn-success" onClick={() => this.addElementToTextarea("[ENC_TOT]")} >Montant Encaissé</button>
                        </InputGroup>
                    </Col>
                </Row>
            </div> :
            <div className="mb-3" >
                <Row>
                    <Col>
                        <button type="button" className="btn btn-primary" onClick={() => this.addElementToTextarea("[COMPTEUR_PLANYO]")} >Compteur Planyo</button>
                    </Col>
                </Row>
            </div >

        var testMailButton = this.state.template.messageTemplateCategory.showButton ?
            <button type="button"
                className="btn btn-secondary float-end"
                onClick={this.sendMailTest}>
                Test : nantesbike@gmail.com
            </button> : ""

        var senderButton = this.state.template.messageTemplateCategory.showButton ?
            <Card.Text>
                <label> Emetteur du mail
                    <select
                        className="form-control form-select required"
                        aria-label="Default select"
                        required
                        value={this.state.template.senderMail}
                        onChange={this.handleSender}
                    >
                        <option defaultValue key="" value="">- Sélectionner -</option>
                        <option key="ne_pas_repondre" value="ne_pas_repondre@nantesbikesolutions.com">ne_pas_repondre@nantesbikesolutions.com</option>
                        <option key="gmail" value="nantesbike@gmail.com">nantesbike@gmail.com</option>
                    </select></label></Card.Text> : ""

        var optionsSignature =
            [<option defaultValue key="" value="">- Selectionner -</option>]

        this.state.signatures.forEach(signature => {
            optionsSignature.push(<option key={signature.id} value={signature.id}>
                {signature.title}
            </option>)
        })


        var selectSignature = this.state.template.messageTemplateCategory.showButton ?
            <div><label>Signature
                <select
                    className="form-control form-select required"
                    aria-label="Default select"
                    value={this.state.template.signature?.id}
                    onChange={this.handleSignature}
                >
                    {optionsSignature}
                </select>
            </label>
                <div className="mb-3" style={{ "whiteSpace": "pre-line" }} >
                    {this.state.template.signature?.message}
                </div>
            </div> : ""

        var optionsScenarii = [<option>- Selecionner -</option>]

        this.state.scenarii.forEach(scenario => {
            optionsScenarii.push(<option value={scenario.id}>
                {scenario.name} {scenario.active ? "(en cours)" : ""}
            </option>)
        })

        var selectScenarii = <select
            required
            className="form-control form-select required"
            aria-label="Default select"
            value={this.state.template.scenario?.id}
            onChange={this.handleScenario}
        >
            {optionsScenarii}
        </select>

        var selectCategory = [<option defaultValue value="">- Selectionner -</option>]
        this.state.categories.forEach(category => {
            selectCategory.push(<option key={category.id} value={category.id}>
                {category.title}
            </option>)
        })

        return (<div>
            <nav className="navbar navbar-dark bg-orange">
                <div className="container-fluid">
                    <span className="navbar-brand mb-0 h1">
                        <h1>Edition du modèle de message</h1>
                    </span>
                </div>
            </nav>
            <div className="container-md">
                <div className="card">
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-header">
                            <div className="row col mb-3">
                                <label>Titre
                                    <input
                                        type="text"
                                        required="required"
                                        name="title"
                                        className="form-control"
                                        value={this.state.template.title}
                                        onChange={this.handleTitle}
                                    ></input>
                                </label>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <label>Catégorie</label>
                                    <select
                                        required="required"
                                        className="form-control form-select required"
                                        aria-label="Default select"
                                        value={this.state.template.messageTemplateCategory.id}
                                        onChange={this.handleCategory}
                                    >
                                        {selectCategory}
                                    </select>
                                </div>

                                <div className="col">
                                    <label>Scénario</label>
                                    <div className="mb-3">
                                        {selectScenarii}
                                    </div>
                                </div>
                            </div>
                            <br />
                            {senderButton}
                            Contenu du message

                            {dynamicButton}
                            <div className="mb-3">
                                <textarea
                                    required="required"
                                    className="form-control"
                                    value={this.state.template.body}
                                    onChange={this.handleBody}
                                    onClick={this.handleBody}
                                    rows="20"
                                ></textarea>
                            </div>
                            <div className="mb-3">
                                {selectSignature}
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary">
                                Enregistrer
                            </button>
                            {testMailButton}
                        </div>
                    </form>
                </div>
            </div>
        </div>);
    }
}
export default withRouter(SaveTemplate);