import { Component } from "react";
import ClientApi from "../swagger-client/ClientApi";
import PDFMaker from "../pdf/PDFMaker";
import {
  withRouter
} from "react-router-dom";
class Update extends Component {
  state = {
    customers: [],
    newBike: {},
    bikes: [],
    previousCustomerVersion: {},
    previousBikeVersion: {}
  };

  finalRequest = { customer: undefined, bike: undefined };

  formId;
  submissionDate;

  customerId;

  dropInfo = "";

  clientApi = new ClientApi();

  componentDidMount() {
    const { id } = this.props.match.params;
    this.formId = id;


    this.clientApi.retrievePendingForm(id).then((res) => {
      var bikeId = -1;

      this.dropInfo = res.data.form.dropInfo

      if (res.data.form.bike !== null) {
        bikeId = res.data.form.bike.id
      }

      var bikesToState = {
        id: bikeId,
        practice: res.data.form.bikeType,
        gender: res.data.form.bikeGender,
        brand: res.data.form.bikeBrand,
        model: res.data.form.bikeModel,
        color: res.data.form.bikeColor,
        dysfunctions: res.data.form.bikeDysfunctions,
        hasAlreadyCome: res.data.form.bikeHasAlreadyCome,
        ebike: res.data.form.ebike
      };

      var customersFromData = [];

      if (res.data.form.customer === null) {
        customersFromData.push({
          id: -1,
          genre: res.data.form.genre,
          surname: res.data.form.surname,
          name: res.data.form.name,
          phoneNumber: res.data.form.phoneNumber,
          mail: res.data.form.mail,
          addressFirstLine: res.data.form.addressFirstLine,
          zipCode: res.data.form.zipCode,
          city: res.data.form.city,
          bikes: [bikesToState],
        });

        res.data.customers.forEach((element) => {
          customersFromData.push(element);
        });
      }
      else {
        this.customerId = res.data.form.customer.id;

        var bikesWithUpdates = []
        res.data.form.customer.bikes.forEach(bike => {
          if (bike.id === bikesToState.id) {
            bikesWithUpdates.push(bikesToState)
          }
          else {
            bikesWithUpdates.push(bike)
          }
        })

        customersFromData.push({
          id: this.customerId,
          genre: res.data.form.genre,
          surname: res.data.form.surname,
          name: res.data.form.name,
          phoneNumber: res.data.form.phoneNumber,
          mail: res.data.form.mail,
          addressFirstLine: res.data.form.addressFirstLine,
          zipCode: res.data.form.zipCode,
          city: res.data.form.city,
          bikes: bikesWithUpdates,
        });
      }

      this.submissionDate = new Date(res.data.form.formSubmissionDate);
      this.setState({
        customers: customersFromData,
        newBike: bikesToState,
        previousCustomerVersion: res.data.form.customer,
        previousBikeVersion: res.data.form.bike
      });

      document.getElementById("full-spinner").setAttribute("hidden", true);
    });
  }

  // ------------------------------------------- //
  // ------------Handler for Bike--------------- //
  // ------------------------------------------- //

  handleSelectCustomer = (event) => {
    this.setState({
      bikes: [],
    });

    var customerFromEvent;
    this.state.customers.forEach((customer, i) => {
      if (customer.id === parseInt(event.target.value)) {
        customerFromEvent = customer;
      }
    });

    if (customerFromEvent.bikes.length > 0) {
      this.setState({
        bikes: customerFromEvent.bikes,
      });
    }

    // On ajoute le client à la finalRequest
    this.finalRequest = {
      customer: customerFromEvent,
      bike: undefined,
    };

    var radioButton = document.getElementById(
      "customer-line-radio-".concat(event.target.value)
    );

    var formElements = [];

    Array.from(document.getElementsByName("radioBike")).forEach(
      (radioButton) => (radioButton.checked = false)
    );

    Array.from(
      document
        .getElementById("customer-line-".concat(event.target.value))
        .getElementsByTagName("input")
    ).forEach((htmlElement) => formElements.push(htmlElement));

    Array.from(
      document
        .getElementById("customer-line-".concat(event.target.value))
        .getElementsByTagName("select")
    ).forEach((htmlElement) => formElements.push(htmlElement));

    Array.from(
      document.getElementById("customer-table").getElementsByTagName("input")
    ).forEach((elt) => {
      if (elt.type !== "radio") {
        elt.setAttribute("disabled", true);
      }
    });

    Array.from(
      document.getElementById("customer-table").getElementsByTagName("select")
    ).forEach((elt) => {
      if (elt.type !== "radio") {
        elt.setAttribute("disabled", true);
      }
    });

    if (radioButton.checked) {
      Array.from(formElements).forEach((elt) =>
        elt.removeAttribute("disabled")
      );
    }
  };

  handleSurname = (idx) => (evt) => {
    const newCustomer = this.state.customers.map((customer, sidx) => {
      if (idx !== sidx) return customer;
      return { ...customer, surname: evt.target.value };
    });

    this.setState({ customers: newCustomer });
  };

  handleName = (idx) => (evt) => {
    const newCustomer = this.state.customers.map((customer, sidx) => {
      if (idx !== sidx) return customer;
      return { ...customer, name: evt.target.value };
    });

    this.setState({ customers: newCustomer });
  };

  handlePhone = (idx) => (evt) => {
    const newCustomer = this.state.customers.map((customer, sidx) => {
      if (idx !== sidx) return customer;
      return { ...customer, phoneNumber: evt.target.value };
    });

    this.setState({ customers: newCustomer });
  };

  handleMail = (idx) => (evt) => {
    const newCustomer = this.state.customers.map((customer, sidx) => {
      if (idx !== sidx) return customer;
      return { ...customer, mail: evt.target.value };
    });

    this.setState({ customers: newCustomer });
  };

  handleAddress = (idx) => (evt) => {
    const newCustomer = this.state.customers.map((customer, sidx) => {
      if (idx !== sidx) return customer;
      return { ...customer, addressFirstLine: evt.target.value };
    });

    this.setState({ customers: newCustomer });
  };

  handleZipCode = (idx) => (evt) => {
    const newCustomer = this.state.customers.map((customer, sidx) => {
      if (idx !== sidx) return customer;
      return { ...customer, zipCode: evt.target.value };
    });

    this.setState({ customers: newCustomer });
  };

  handleCity = (idx) => (evt) => {
    const newCustomer = this.state.customers.map((customer, sidx) => {
      if (idx !== sidx) return customer;
      return { ...customer, city: evt.target.value };
    });

    this.setState({ customers: newCustomer });
  };

  handleGender = (idx) => (evt) => {
    const newCustomer = this.state.customers.map((customer, sidx) => {
      if (idx !== sidx) return customer;
      return { ...customer, genre: evt.target.value };
    });

    this.setState({ customers: newCustomer });
  };

  // ------------------------------------------- //
  // ------------Handler for Bike--------------- //
  // ------------------------------------------- //
  handleSelectBike = (event) => {
    var radioButton = document.getElementById(
      "bike-line-radio-".concat(event.target.value)
    );

    if (parseInt(event.target.value) === this.state.newBike.id) {
      this.finalRequest = {
        customer: this.finalRequest.customer,
        bike: this.state.newBike,
      };
    } else {
      this.state.bikes.forEach((thisBike, i) => {
        if (thisBike.id === parseInt(event.target.value)) {
          this.finalRequest = {
            customer: this.finalRequest.customer,
            bike: thisBike,
          };
        }
      });
    }

    var formElements = [];

    Array.from(
      document
        .getElementById("bike-line-".concat(event.target.value))
        .getElementsByTagName("input")
    ).forEach((htmlElement) => formElements.push(htmlElement));

    Array.from(
      document
        .getElementById("bike-line-".concat(event.target.value))
        .getElementsByTagName("select")
    ).forEach((htmlElement) => formElements.push(htmlElement));

    Array.from(
      document.getElementById("bike-table").getElementsByTagName("input")
    ).forEach((elt) => {
      if (elt.type !== "radio") {
        elt.setAttribute("disabled", true);
      }
    });

    Array.from(
      document.getElementById("bike-table").getElementsByTagName("select")
    ).forEach((elt) => {
      if (elt.type !== "radio") {
        elt.setAttribute("disabled", true);
      }
    });

    if (radioButton.checked) {
      Array.from(formElements).forEach((elt) =>
        elt.removeAttribute("disabled")
      );
    }
  };

  handleBikePractice = (idx) => (evt) => {
    if (idx >= 0) {
      const newBike = this.state.bikes.map((bike, sidx) => {
        if (idx !== sidx) return bike;
        return { ...bike, practice: evt.target.value };
      });

      this.setState({ bikes: newBike });
    } else {
      const newBike = this.state.newBike;
      newBike.practice = evt.target.value;
      this.setState({ newBike: newBike });
    }
  };

  handleBikeGender = (idx) => (evt) => {
    if (idx >= 0) {
      const newBike = this.state.bikes.map((bike, sidx) => {
        if (idx !== sidx) return bike;
        return { ...bike, gender: evt.target.value };
      });

      this.setState({ bikes: newBike });
    } else {
      const newBike = this.state.newBike;
      newBike.gender = evt.target.value;
      this.setState({ newBike: newBike });
    }
  };

  handleBikeBrand = (idx) => (evt) => {
    if (idx >= 0) {
      const newBike = this.state.bikes.map((bike, sidx) => {
        if (idx !== sidx) return bike;
        return { ...bike, brand: evt.target.value };
      });

      this.setState({ bikes: newBike });
    } else {
      const newBike = this.state.newBike;
      newBike.brand = evt.target.value;
      this.setState({ newBike: newBike });
    }
  };

  handleBikeModele = (idx) => (evt) => {
    if (idx >= 0) {
      const newBike = this.state.bikes.map((bike, sidx) => {
        if (idx !== sidx) return bike;
        return { ...bike, model: evt.target.value };
      });

      this.setState({ bikes: newBike });
    } else {
      const newBike = this.state.newBike;
      newBike.model = evt.target.value;
      this.setState({ newBike: newBike });
    }
  };

  handleBikeColor = (idx) => (evt) => {
    if (idx >= 0) {
      const newBike = this.state.bikes.map((bike, sidx) => {
        if (idx !== sidx) return bike;
        return { ...bike, color: evt.target.value };
      });

      this.setState({ bikes: newBike });
    } else {
      const newBike = this.state.newBike;
      newBike.color = evt.target.value;
      this.setState({ newBike: newBike });
    }
  };

  handleBikeDysfunctions = (idx) => (evt) => {
    if (idx >= 0) {
      const newBike = this.state.bikes.map((bike, sidx) => {
        if (idx !== sidx) return bike;
        return { ...bike, dysfunctions: evt.target.value };
      });

      this.setState({ bikes: newBike });
    } else {
      const newBike = this.state.newBike;
      newBike.dysfunctions = evt.target.value;
      this.setState({ newBike: newBike });
    }
  };


  handleEBike = (idx) => (evt) => {
    if (idx >= 0) {
      const newBike = this.state.bikes.map((bike, sidx) => {
        if (idx !== sidx) return bike;
        return { ...bike, ebike: evt.target.value };
      });

      this.setState({ bikes: newBike });
    } else {
      const newBike = this.state.newBike;
      newBike.ebike = evt.target.value;
      this.setState({ newBike: newBike });
    }
  }

  // ------------------------------------------- //
  // ------------------SUBMITS------------------ //
  // ------------------------------------------- //
  handleSubmit = (event) => {
    event.preventDefault();
  };

  submitWithMail = (event) => {
    this.submitForm(true);
  }

  submitWithoutMail = (event) => {
    this.submitForm(false);
  }

  checkSubmissionForm() {

    var finalCustomer;

    if (this.finalRequest.customer !== undefined) {
      this.state.customers.forEach((customer, i) => {
        if (customer.id === this.finalRequest.customer.id) {
          finalCustomer = customer;
        }
      });
    }

    var finalBike;

    if (this.finalRequest.bike !== undefined) {
      if (this.finalRequest.bike.id === this.state.newBike.id) {
        finalBike = this.state.newBike;
      } else {
        this.state.bikes.forEach((thisBike, i) => {
          if (this.finalRequest.bike.id === thisBike.id) {
            finalBike = thisBike;
          }
        });
      }
    }

    this.finalRequest = {
      customer: finalCustomer,
      bike: finalBike,
    };

    return this.finalRequest.customer !== undefined &&
      this.finalRequest.bike !== undefined
  }

  submitForm(sendMail = false) {
    if (this.checkSubmissionForm()) {
      document.getElementById("full-spinner").setAttribute("hidden", false);
      // envoie les données
      this.clientApi.saveCustomer(this.finalRequest.customer, this.finalRequest.bike, this.formId, sendMail)
        .then((res) => {
          new PDFMaker().createPdfForFolder(
            this.formId,
            this.submissionDate,
            res.data,
            this.state.newBike.dysfunctions,
            this.dropInfo,
          );

          this.props.history.push("/");
        }).catch = (errors) => {
          alert("Erreur au niveau du serveur");
        };
    }
    else {
      alert("Vous devez selectionner un couple client/velo");
    }
  }

  // ------------------------------------------- //
  // ------------------RENDERS------------------ //
  // ------------------------------------------- //

  renderPreBikeLine() {
    if (this.state.previousBikeVersion !== null) {
      return (
        <tr className="table-warning">
          <td>
            Avant :
          </td>
          <td>
            {this.state.newBike.practice !== this.state.previousBikeVersion.practice ? this.state.previousBikeVersion.practice : ""}
          </td>
          <td>
            {this.state.newBike.gender !== this.state.previousBikeVersion.gender ? this.state.previousBikeVersion.gender : "" }
          </td>
          <td>
            {this.state.newBike.brand !== this.state.previousBikeVersion.brand ? this.state.previousBikeVersion.brand : ""}
          </td>
          <td>
            {this.state.newBike.model !== this.state.previousBikeVersion.model ? this.state.previousBikeVersion.model : ""}
          </td>
          <td>
            {this.state.newBike.color !== this.state.previousBikeVersion.color ? this.state.previousBikeVersion.color : ""}
          </td>
          <td>
            {this.state.newBike.ebike !== this.state.previousBikeVersion.ebike ? this.state.previousBikeVersion.ebike : ""}
          </td>
          <td>
          </td>
        </tr>
      )
    }
  }

  renderBikeLine(bike, idx) {
    var tableClass = "";
    if (idx < 0) {
      tableClass = "table-primary";
    }

    return (
      <tr id={"bike-line-".concat(bike.id)} className={tableClass}>
        <td>
          <div className="form-check">
            <label>
              <input
                class="form-check-input"
                type="radio"
                name="radioBike"
                id={"bike-line-radio-".concat(bike.id)}
                value={bike.id}
                onChange={this.handleSelectBike}
              />
              {bike.id === -1 ? "Nouveau" : bike.id}
            </label>
          </div>
        </td>
        <td>
          <select
            required="required"
            className="form-control form-select required"
            aria-label="Default select"
            disabled
            value={bike.practice}
            onChange={this.handleBikePractice(idx)}
          >
            <option defaultValue value="">
              - Selectionner -
                    </option>
            <option value="Ville">Ville</option>
            <option value="VTC">V.T.C.</option>
            <option value="VTT">V.T.T.</option>
            <option value="Route">Route</option>
            <option value="BMX">B.M.X.</option>
          </select>
        </td>
        <td>
          <select
            required="required"
            className="form-control form-select required"
            aria-label="Default select"
            disabled
            value={bike.gender}
            onChange={this.handleBikeGender(idx)}
          >
            <option defaultValue value="">
              - Selectionner -
                    </option>
            <option value="Homme">Homme</option>
            <option value="Femme">Femme</option>
            <option value="Enfant">Enfant</option>
            <option value="Mixte">Mixte</option>
          </select>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control"
            value={bike.brand}
            onChange={this.handleBikeBrand(idx)}
          ></input>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control"
            value={bike.model}
            onChange={this.handleBikeModele(idx)}
          ></input>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control"
            value={bike.color}
            onChange={this.handleBikeColor(idx)}
          ></input>
        </td>
        <td>
          <select
            required="required"
            className="form-control form-select required"
            aria-label="Default select"
            disabled
            value={bike.ebike}
            onChange={this.handleEBike(idx)}
          >
            <option defaultValue value="">
              - Selectionner -
                    </option>
            <option value={true}>Oui</option>
            <option value={false}>Non</option>
          </select>
        </td>
        <td>{bike.id === -1 ? (bike.hasAlreadyCome ? "Oui" : "Non") : "--"}</td>
      </tr>
    );
  }

  renderPreCustomerLine(customer) {
    if (customer.id === this.customerId) {
      return (<tr className="table-warning" >
        <td>
          Avant :
        </td>
        <td>
          {parseInt(customer.genre) !== parseInt(this.state.previousCustomerVersion.genre) ? (parseInt(this.state.previousCustomerVersion.genre) === 1 ? "M." : "Mme") : ""}
        </td>
        <td>
          {customer.surname !== this.state.previousCustomerVersion.surname ? this.state.previousCustomerVersion.surname : ""}
        </td>
        <td>
          {customer.name !== this.state.previousCustomerVersion.name ? this.state.previousCustomerVersion.name : ""}
        </td>
        <td>
          {customer.phoneNumber !== this.state.previousCustomerVersion.phoneNumber ? this.state.previousCustomerVersion.phoneNumber : ""}
        </td>
        <td>
          {customer.mail !== this.state.previousCustomerVersion.mail ? this.state.previousCustomerVersion.mail : ""}
        </td>
        <td>
          {customer.addressFirstLine !== this.state.previousCustomerVersion.addressFirstLine ? this.state.previousCustomerVersion.addressFirstLine : ""}
        </td>
        <td>
          {customer.zipCode !== this.state.previousCustomerVersion.zipCode ? this.state.previousCustomerVersion.zipCode : ""}
        </td>
        <td>
          {customer.city !== this.state.previousCustomerVersion.city ? this.state.previousCustomerVersion.city : ""}
        </td>
        <td></td>
      </tr>);
    }
  }

  renderCustomerLine(idx, customer) {
    var classValue = "";
    if (parseInt(customer.id) === -1 || customer.id === this.customerId) {
      classValue = "table-primary";
    }
    return (
      <tr className={classValue} id={"customer-line-".concat(customer.id)}>
        <td>
          <div className="form-check">
            <label>
              <input
                class="form-check-input"
                type="radio"
                name="radioCustomer"
                id={"customer-line-radio-".concat(customer.id)}
                onChange={this.handleSelectCustomer}
                value={customer.id}
              />
              {customer.id === -1 ? "Nouveau" : customer.id}
            </label>
          </div>
        </td>
        <td>
          <select
            id={"customer-line-gender-".concat(customer.id)}
            required
            disabled
            className="form-control form-select required"
            aria-label="Default select"
            value={customer.genre}
            onChange={this.handleGender(idx)}
          >
            <option defaultValue value="">
              - Sélectionner -
            </option>
            <option value="1">M.</option>
            <option value="2">Mme.</option>
          </select>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control"
            value={customer.surname}
            onChange={this.handleSurname(idx)}
          ></input>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control"
            value={customer.name}
            onChange={this.handleName(idx)}
          ></input>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control"
            value={customer.phoneNumber}
            onChange={this.handlePhone(idx)}
          ></input>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control"
            value={customer.mail}
            onChange={this.handleMail(idx)}
          ></input>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control"
            value={customer.addressFirstLine}
            onChange={this.handleAddress(idx)}
          ></input>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control"
            value={customer.zipCode}
            onChange={this.handleZipCode(idx)}
          ></input>
        </td>
        <td>
          <input
            type="text"
            disabled
            className="form-control" x
            value={customer.city}
            onChange={this.handleCity(idx)}
          ></input>
        </td>
        <td>{customer.bikes.length}</td>
      </tr>
    );
  }

  render() {
    return (
      <div>
        <nav class="navbar navbar-dark bg-orange">
          <div class="container-fluid">
            <span class="navbar-brand mb-0 h1">
              <h1>Rendez-vous à valider</h1>
            </span>
          </div>
        </nav>
        <div id="full-spinner" className="full-spinner">
          <div class="spinner-grow sf-spinner" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div>
            <h4 className="bg-light">Client</h4>
            <table id="customer-table" className="table">
              <thead>
                <tr>
                  <th className="col-sm" scope="col">#</th>
                  <th className="col-sm" scope="col">Genre</th>
                  <th className="col-md" scope="col">Nom</th>
                  <th className="col-md" scope="col">Prénom</th>
                  <th className="col-md" scope="col">Telephone</th>
                  <th className="col-lg" scope="col">e-mail</th>
                  <th className="" scope="col">Adresse</th>
                  <th className="col-sm" scope="col">Code postal</th>
                  <th className="col-sm" scope="col">Ville</th>
                  <th className="col-sm" scope="col">Nb velo</th>
                </tr>
              </thead>
              <tbody>
                {this.state.customers.map((customer) => {
                  return this.renderPreCustomerLine(customer);
                })}
                {this.state.customers.map((customer, idx) => {
                  return this.renderCustomerLine(idx, customer);
                })}
              </tbody>
            </table>
          </div>

          <div>
            <h4 className="bg-light">Pour le vélo :</h4>
            <table id="bike-table" className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Pratique</th>
                  <th scope="col">Genre</th>
                  <th scope="col">Marque</th>
                  <th scope="col">Modèle</th>
                  <th scope="col">Couleurs</th>
                  <th scope="col">VAE</th>
                  <th scope="col">Déjà venu?</th>
                </tr>
              </thead>
              <tbody>
                {this.renderPreBikeLine()}
                {this.renderBikeLine(this.state.newBike, -1)}
                {this.state.bikes.map((bike, idx) => {
                  if (bike.id !== this.state.newBike.id) {
                    return this.renderBikeLine(bike, idx);
                  } else return null;
                })}
              </tbody>
            </table>
            <div>
              <h4 className="bg-light">Infos sur le dépot de vélo : </h4>
              <div className="container-md">
                {this.dropInfo}
              </div>
            </div>
            <div>
              <h4 className="bg-light">Raison de la visite :</h4>
              <div className="container-md">
                {this.state.newBike.dysfunctions}
              </div>
            </div>
          </div>
        </form>
        <div className="container-sm">
          <button className="btn btn-primary btn-small-margin"
            onClick={this.submitWithoutMail}>
            Valider la demande</button>
        </div>

      </div>
    );
  }
}
export default withRouter(Update);