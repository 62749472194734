import ClientApi from "./ClientApi";

export default class ClientTemplateAPI extends ClientApi {
    saveMessageTemplateCategory(category) {
        return this.postAxiosWithBody("admin/templates/category/save", category);
    }

    retrieveCategories() {
        return this.getAxios("admin/templates/categories");
    }

    retrieveCategory(idCategory) {
        return this.getAxios("admin/templates/category/".concat(idCategory));
    }

    retrieveMessageTemplates() {
        return this.getAxios("admin/templates/");
    }

    retrieveMessageTemplate(id) {
        return this.getAxios("admin/template/".concat(id));
    }

    saveMessageTemplate(messageTemplate) {
        return this.postAxiosWithBody("admin/template/create/", messageTemplate);
    }

    deleteMessageTemplate(id) {
        return this.deleteAxios("admin/template/delete/".concat(id));
    }

    deleteCategory(id) {
        return this.deleteAxios("admin/templates/category/delete/".concat(id));
    }

    // Send Message Template
    sendMessageTest(template) {
        return this.postAxiosWithBody("admin/template/testmail/", template);
    }

    sendConfimationMessageTemplate(template) {
        return this.postAxiosWithBody("admin/mailtemplate/test/confirmation/", template);
    }

    sendAppointmentMessageTemplate(template) {
        return this.postAxiosWithBody("admin/mailtemplate/test/appointment/", template);
    }

    // Signatures 
    saveSignature(signature) {
        return this.postAxiosWithBody("admin/templates/signature/save", signature);
    }

    retrieveSignatures() {
        return this.getAxios("admin/templates/signatures");
    }

    deleteSignature(id) {
        return this.deleteAxios("admin/templates/signature/delete/".concat(id));
    }

}