import React, { Component } from "react";
import ClientTemplatesAPI from "../../swagger-client/ClientTemplatesAPI";
import Modal from "react-bootstrap/Modal";

import {
    withRouter
} from "react-router-dom";
import { Badge } from "react-bootstrap";
class TemplateLine extends Component {
    clientApi = new ClientTemplatesAPI();
    template = this.props.message;

    state = { showModal: false }

    updateTemplate = (event) => {
        this.props.history.push("/template/update/".concat(this.template.id))
    }

    deleteMessage = (event) => {
        this.clientApi.deleteMessageTemplate(this.template.id).then((res) => {
            this.template = undefined
            this.dismissModal()
        })
    }

    showModal = (event) => {
        this.setState({ showModal: true })
    }

    dismissModal = (event) => {
        this.setState({ showModal: false })
    }

    render() {
        if (this.template !== undefined) {

            var deleteButton = ""
            if (!this.template.active) {
                deleteButton = <div className="mb-1">
                    <button type="button" onClick={this.showModal} className="btn btn-danger">Supprimer</button>
                </div>
            }

            return <tr>
                <td>{this.template.scenario.name} {this.template.scenario.active ? <Badge className="bg-success">En cours</Badge> : ""}</td>
                <td>{this.template.title}</td>
                <td style={{ "white-space": "pre-line" }}>{this.template.body.substring(0, 200).concat("...")}</td>
                <td>{this.template.senderMail}</td>
                <td>
                    <Modal show={this.state.showModal}>
                        <Modal.Header>
                            <Modal.Title>
                                Cette action est irréversible
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Êtes-vous certain de vouloir supprimer ce modèle de message?
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.dismissModal}>
                                Non
                            </button>
                            <button className="btn btn-danger" onClick={this.deleteMessage}>
                                Supprimer
                            </button>
                        </Modal.Footer>
                    </Modal>
                    <div className="mb-1">
                        <button type="button" onClick={this.updateTemplate} className="btn btn-primary">Modifier</button>
                    </div>
                    {deleteButton}
                </td>
            </tr>
        }
        else {
            return "";
        }
    }
}
export default withRouter(TemplateLine)