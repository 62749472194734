import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, Container, Badge, Button, Modal, Form, Spinner } from "react-bootstrap";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ClientWebPageAPI from '../../swagger-client/ClientWepPageAPI';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 1;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 1,
    margin: "1 2 2 1",

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '',
    padding: grid,
    width: "100%"
});

class PageManager extends Component {

    clientApi = new ClientWebPageAPI()

    state = {
        items: [],
        selected: [],
        modal: {}
    };

    componentDidMount() {
        let { pageId } = this.props.match.params;
        this.loadPage(pageId);
    }

    componentWillReceiveProps(nextProps) {
        let { pageId } = nextProps.match.params;
        this.loadPage(pageId);
    }

    loadPage(pageId) {
        this.clientApi.retrieveWebPageManager(pageId)
            .then(res => {
                this.findMessageForCategories(res.data.items, res.data);
                this.findMessageForCategories(res.data.selected, res.data);
            })
            .catch(error => {
                this.setState({
                    modal: {
                        title: "Une erreur est survenue",
                        message: error.message,
                        show: true
                    }
                })
            })
    }

    findMessageForCategories(categories, state) {
        categories.forEach(element => {
            this.clientApi.retrieveMessageForCategory(element.id, state.scenario.id)
                .then(resMessage => {
                    element.message = resMessage.data
                    element.message.body = element.message.body.substring(0, 120).concat("...")
                    this.setState(state)
                })
                .catch(error => {
                    element.message = {
                        body: (<p>Pas de message trouvé pour cette catégorie.
                            <br /><br />
                            <Link to={"/templates/new/".concat(element.id).concat("/").concat(state.scenario.id)} >
                                Créer le message
                            </Link>
                        </p>),
                        title: error.message
                    }
                    this.setState(state)
                })
        });
    }

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'droppable2') {
                state = { selected: items };
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2
            });
        }
    };

    savePage = () => {
        var request = {
            id: this.state.idPage,
            title: this.state.titlePage,
            categories: this.state.selected
        }

        this.setState({
            modal: {
                title: "Opération en cours",
                message: <span><Spinner animation="grow" /> La production du site prends plusieurs minutes... </span>,
                show: true
            }
        }
        )

        this.clientApi.savePage(request)
            .then(res => {
                this.setState({
                    modal: {
                        title: "Operation réussi",
                        message: "",
                        show: true
                    }
                })
            })
            .catch(error => {
                this.setState({
                    modal: {
                        title: "Une erreur est survenue lors de la sauvegarde en BDD",
                        message: error.message,
                        show: true
                    }
                })
            })
    }

    dismissModal = () => {
        this.setState({
            modal: {
                title: "",
                message: "",
                show: false
            }
        })
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <div>
                <nav className="navbar navbar-dark bg-orange">
                    <div className="container-fluid">
                        <span className="navbar-brand mb-0 h1">
                            <h1>
                                Gestion Site pour Scénario {this.state.scenario?.id} : {this.state.scenario?.name}{" "}
                                {this.state.scenario?.active ? <Badge className="bg-primary">En cours</Badge> : ""}
                            </h1>
                        </span>
                    </div>
                </nav>

                <Modal show={this.state.modal.show}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.modal.title}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modal.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.dismissModal}>
                            Fermer
                        </button>
                    </Modal.Footer>
                </Modal>

                <Container>
                    <br />
                    <h2>Titre de la page </h2>
                    <Form.Control type="input" value={this.state.titlePage} onChange={this.handleTitle} />

                    <br />
                    <h2>Contenu</h2>

                    <DragDropContext onDragEnd={this.onDragEnd}>

                        <Row>
                            <Col>
                                <h3>
                                    Article(s) <Badge className="bg-warning">Hors Ligne</Badge>
                                </h3>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}>
                                            {this.state.items.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id.toString()}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <Card
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}>

                                                            <Card.Header>
                                                                <Card.Title>{item.message?.title}</Card.Title>
                                                                <Badge className="bg-warning">
                                                                    {item.title}
                                                                </Badge>
                                                            </Card.Header>
                                                            <Card.Body style={{ whiteSpace: "pre-line" }}>
                                                                {item.message?.body}
                                                            </Card.Body>
                                                        </Card>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </Col>
                            <Col>
                                <h3>Article(s) <Badge className="bg-success">En Ligne</Badge></h3>


                                <Droppable droppableId="droppable2">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}>
                                            {this.state.selected.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id.toString()}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <Card
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}>
                                                            <Card.Header>
                                                                <Card.Title>{item.message?.title}</Card.Title>
                                                                <Badge className="bg-success">
                                                                    {item.title}
                                                                </Badge>
                                                            </Card.Header>
                                                            <Card.Body style={{ whiteSpace: "pre-line" }}>
                                                                {item.message?.body}
                                                            </Card.Body>
                                                        </Card>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                            </Col>
                        </Row>
                    </DragDropContext>
                    <div className="float-end" >
                        <Button type="button" onClick={this.savePage} >Enregistrer les modifications</Button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                </Container >

            </div >
        );
    }
}
export default withRouter(PageManager)