import React, { Component } from "react";
import ClientTemplateAPI from "../../../swagger-client/ClientTemplatesAPI";
import {
    withRouter
} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class Signature extends Component {
    clientApi = new ClientTemplateAPI();

    state = {
        signature: this.props.message,
        modal: {
            show: false,
            message: "",
            title: "",
            modification: false,
        }
    }

    handleSubmitSignature = (event) => {
        event.preventDefault();
        this.clientApi.saveSignature(this.state.signature).then(res => {
            this.setState({ modal: { show: true, message: "Opération réussie" } });
        }).catch = (errors) => {
            this.setState({ modal: { show: true, message: errors.toString() } });
        };
    }

    handleTitle = (event) => {
        var signature = this.state.signature
        signature.title = event.target.value

        this.setState({ signature: signature })
    }

    handleMessage = (event) => {
        var signature = this.state.signature
        signature.message = event.target.value

        this.setState({ signature: signature })
    }

    deleteSignature = () => {
        this.clientApi.deleteSignature(this.state.signature.id)
        this.setState({ signature: undefined })
        this.dismissModal()
    }

    showDeleteModal = () => {
        this.setState({
            modal: {
                show: true,
                message: "Etes-vous sûr de vouloir effectuer cette action?",
                title: "Suppression définitive",
                modification: true
            }
        })
    }

    dismissModal = () => {
        this.setState({ modal: { show: false, message: "", title: "", modification: false } })
    }

    render() {
        if (this.state.signature !== undefined) {

            var deleteButton = this.state.modal.modification? <button className="btn btn-danger" onClick={this.deleteSignature}>
            Supprimer
        </button> : ""

            return (<Card style={{ width: '30rem', display: 'inline-block', margin: '1em' }}>
                <Form onSubmit={this.handleSubmitSignature}>
                    <Card.Body>
                        <Form.Group>
                            <Form.Label>
                                Titre
                                    </Form.Label>
                            <Form.Control type="text" value={this.state.signature.title} onChange={this.handleTitle} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Contenu
                                    </Form.Label>
                            <Form.Control as="textarea" rows={10} value={this.state.signature.message} onChange={this.handleMessage} />
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <Button type="submit" className="btn-small-margin">Enregistrer</Button>
                        <Button type="button" variant="danger" className="btn-small-margin" onClick={this.showDeleteModal}>Supprimer</Button>
                    </Card.Footer>
                </Form>
                <Modal show={this.state.modal.show}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.modal.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modal.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.dismissModal}>
                            Fermer
                        </button>
                        {deleteButton}
                    </Modal.Footer>
                </Modal>
            </Card>);
        }
        else {
            return "";
        }
    }
}
export default withRouter(Signature)