import ClientApi from "./ClientApi";


export default class ClientSalesApi extends ClientApi {


    saveSale(sale) {
        return this.postAxiosWithBody("admin/webpage/sale/save", sale)
    }

    saveSales(sales) {
        return this.postAxiosWithBody("admin/webpage/sales/save", sales)
    }

    retrieveSales() {
        return this.getAxios("admin/webpage/sales")
    }

    removeSale(saleId) {
        return this.postAxiosNoBody("admin/webpage/sales/delete/".concat(saleId))
    }

    retriveDynamicPage(dynamicPageId) {
        return this.getAxios(
            "admin/dynamicpage/"
                .concat(dynamicPageId))
    }

    saveDynamicPage(dynamicPageRequest) {
        return this.postAxiosWithBody("admin/save-dynamicpage/", dynamicPageRequest);
    }
}