import React, { Component } from "react";
import {
    withRouter
} from "react-router-dom";
import ClientTemplateAPI from "../../../swagger-client/ClientTemplatesAPI"
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Signature from "./Signature";
import Modal from "react-bootstrap/Modal";

class Signatures extends Component {

    clientApi = new ClientTemplateAPI();

    state = {
        newSignature: {},
        signatures: [],
        modal: {
            show: false,
            message: ""
        }
    }

    componentDidMount() {
        this.clientApi.retrieveSignatures().then((res) => {
            const signatures = res.data;
            this.setState({ signatures: signatures });
        });
    }

    handleNewSignature = (event) => {
        event.preventDefault();
        this.clientApi.saveSignature(this.state.newSignature).then(res => {
            this.state.signatures.push(res.data)
            this.setState({ 
                newSignature: {
                    title : "",
                    message : ""
                },
                modal: { show: true, message: "Opération réussie" } });
        }).catch = (errors) => {
            this.setState({ modal: { show: true, message: errors.toString() } });
        };
    }

    handleTitle = (event) => {
        var newSignature = this.state.newSignature
        newSignature.title = event.target.value
        this.setState({ newSignature: newSignature })
    }

    handleMessage = (event) => {
        var newSignature = this.state.newSignature
        newSignature.message = event.target.value
        this.setState({ newSignature: newSignature })
    }

    dismissModal = () => {
        this.setState({ modal: { show: false, message: "" } })
    }

    render() {
        const signatureLine = this.state.signatures.map((it) => (
            <Signature key={it.id} message={it} />
        ));

        return (
            <div>
                <nav class="navbar navbar-dark bg-orange">
                    <div class="container-fluid">
                        <span class="navbar-brand mb-0 h1">
                            <h1>Gestion des signatures</h1>
                        </span>
                    </div>
                </nav>
                <div className="container">
                    <Modal show={this.state.modal.show}>
                        <Modal.Header>
                            <Modal.Title>
                                Résultat de l'action
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.modal.message}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.dismissModal}>
                                Fermer
                        </button>
                        </Modal.Footer>
                    </Modal>
                    <h2>Créer une nouvelle signature</h2>
                    <Card style={{ width: '30rem', margin: '1em' }} >
                        <Form onSubmit={this.handleNewSignature}>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label>
                                        Titre
                                    </Form.Label>
                                    <Form.Control type="text" value={this.state.newSignature.title} onChange={this.handleTitle} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Contenu
                                    </Form.Label>
                                    <Form.Control as="textarea" rows={10} value={this.state.newSignature.message} onChange={this.handleMessage} />
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="primary" type="submit">Créer</Button>
                            </Card.Footer>
                        </Form>
                    </Card>
                    <h2>Signatures existantes</h2>
                    {signatureLine}
                </div>
            </div>);
    }
}
export default withRouter(Signatures);