import React, { Component } from "react";
import { Container, Form, Button, Card, Row, Col, Modal } from "react-bootstrap";
import FileBase64 from 'react-file-base64';
import ClientWebPageAPI from "../../swagger-client/ClientWepPageAPI";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LogoVertical from "../../resources/vertical.png"

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '',
    padding: 1,
    width: "100%"
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 1,
    margin: "1 2 2 1",

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '',

    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class Partners extends Component {

    clientApi = new ClientWebPageAPI()

    state = {
        newpartner: {},
        partners: [],
        show: false,
    }

    componentDidMount() {
        this.clientApi.retrievePartners().then(res => {
            this.setState({ partners: res.data })
        })
    }

    createPartnerSubmit = (event) => {
        event.preventDefault();

        this.state.newpartner.position = this.state.partners.length;

        this.clientApi.savePartner(this.state.newpartner)
            .then(res => {
                this.state.partners.push(res.data)
                this.setState({
                    newpartner: {},
                    partners: this.state.partners,
                    show: false
                })
            })
            .catch(error => {
                alert(error)
            });
    }

    handleName = (event) => {
        var actualState = this.state;
        actualState.newpartner.name = event.target.value;
        this.setState(actualState)
    }

    handleDescription = (event) => {
        var actualState = this.state;
        actualState.newpartner.description = event.target.value;
        this.setState(actualState)
    }

    handleLink = (event) => {
        var actualState = this.state;
        actualState.newpartner.link = event.target.value;
        this.setState(actualState)
    }


    setFile(files) {
        var actualState = this.state;
        actualState.newpartner.picture = files.base64;

        this.setState(actualState)
    }

    handleNameIndexed = (index, event) => {
        var partner = this.state.partners[index];
        partner.name = event.target.value;
        this.setState(this.state);
        
    }

    handleDescriptionIndexed = (index, event) => {
        var partner = this.state.partners[index];
        partner.description = event.target.value;
        this.setState(this.state);
        
    }

    handleLinkIndexed = (index, event) => {
        var partner = this.state.partners[index];
        partner.link = event.target.value;
        this.setState(this.state);
        
    }

    handleIndexedFile = (index, files) => {
        var partner = this.state.partners[index];
        partner.picture = files.base64;
        this.setState(this.state);
        this.savePartner();
    }

    savePartner = () => {
        this.clientApi.savePartners(this.state.partners)
        .then(res => {

        })
        .catch(error => {
            alert(error)
        })
    }

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.partners,
            result.source.index,
            result.destination.index
        );


        items.forEach((partner, index) => {
            partner.position = index
        })

        this.setState({
            partners: items
        });

        this.clientApi.savePartners(items)
            .then(res => {

            })
            .catch(error => {
                alert(error)
            })

    }

    showModal = () => {
        this.setState({ show: true })
    }

    hideModal = () => {
        this.setState({ newpartner: {}, show: false })
    }

    deletePartner = (idPartner, index, event) => {
        this.clientApi.removePartner(idPartner)
            .then(res => {
                this.state.partners.splice(index, 1);
                this.setState(this.state);
            })
            .catch(error => {
                alert(error);
            })
    }

    render() {
        return (<div>
            <nav className="navbar navbar-dark bg-orange">
                <div className="container-fluid">
                    <span className="navbar-brand mb-0 h1">
                        <h1>
                            Gestion des partenaires
                        </h1>
                    </span>
                    <Button variant="primary" type="button" onClick={this.showModal} >Nouveau</Button>
                </div>
            </nav>
            <Modal show={this.state.show} onHide={this.hideModal} centered size="lg" >
                <Modal.Header closeButton closeLabel="Fermer">
                    <Modal.Title>Création d'un partenaire</Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.createPartnerSubmit}>
                    <Modal.Body>
                        <Card>
                            <Card.Header>
                                <Form.Label>
                                    Nom du partenaire
                                </Form.Label>
                                <Form.Control value={this.state.newpartner.name} onChange={this.handleName} lg></Form.Control>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <img src={this.state.newpartner.picture} className="img-partner" />

                                        <FileBase64 multiple={false} onDone={this.setFile.bind(this)} />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label>
                                                    Description
                                                </Form.Label>
                                                <Form.Control as="textarea" rows={6} value={this.state.newpartner.description} onChange={this.handleDescription}></Form.Control>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label>
                                                    Site internet
                                                </Form.Label>
                                                <Form.Control value={this.state.newpartner.link} onChange={this.handleLink}></Form.Control>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">Ajouter</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Container>
                <h1>Organisation de la page</h1>

                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.partners.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <Card >
                                                    <Card.Header>
                                                        <Row>
                                                            <Col xs="1">
                                                                <img src={LogoVertical} alt="<>" />
                                                            </Col>
                                                            <Col xs="9">
                                                                <Form.Control value={item.name} onChange={(event) => this.handleNameIndexed(index, event)} onBlur={this.savePartner} />
                                                            </Col>
                                                            <Col>
                                                                <Button type="button" variant="danger" className="float-end" onClick={(event) => this.deletePartner(item.id, index, event)} >Supprimer</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs="4">
                                                                <img alt={item.name} src={item.picture} className="img-partner" />
                                                                <FileBase64 multiple={false} onDone={(event) => this.handleIndexedFile(index, event)} />
                                                            </Col>
                                                            <Col>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Label>
                                                                            Description
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={6}
                                                                            value={item.description}
                                                                            onChange={(event) => this.handleDescriptionIndexed(index, event)} onBlur={this.savePartner}>
                                                                        </Form.Control>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Label>
                                                                            Site Internet
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            value={item.link}
                                                                            onChange={(event) => this.handleLinkIndexed(index, event)} onBlur={this.savePartner}></Form.Control>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>
        </div>)
    }
}
export default Partners