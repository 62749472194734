import { Component } from "react";
import { Button, Card, Form, Modal, InputGroup } from "react-bootstrap";
import ClientScenariiAPI from "../../swagger-client/ClientScenarii";

export default class Scenario extends Component {

    clientApi = new ClientScenariiAPI();

    state = {
        scenario: this.props.message,
        modal: {
            show: false,
            message: "",
            title: "",
            modification: false,
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.clientApi.saveScenario(this.state.scenario).then(res => {
            this.setState({ modal: { show: true, message: "Opération réussie" } });
        }).catch = (errors) => {
            this.setState({ modal: { show: true, message: errors.toString() } });
        };
    }

    handleName = (event) => {
        var scenario = this.state.scenario
        scenario.name = event.target.value

        this.setState({ scenario: scenario })
    }

    handleIsActive = (event) => {
        var scenario = this.state.scenario
        scenario.active = event.target.checked

        this.setState({ scenario: scenario })

        document.getElementsByName("scenario-active").forEach(elmt => {
            elmt.checked = false;
        })

    }

    dismissModal = () => {
        this.setState({ modal: { show: false, message: "", title: "", modification: false } })
        document.location = "/scenarii"
    }

    render() {
        if (this.state.scenario !== undefined) {

            return (<Card style={{ width: '18rem', display: 'inline-block', margin: '1em' }}>
                <Form onSubmit={this.handleSubmit}>
                    <Card.Body>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>{this.state.scenario.id}</InputGroup.Text>
                            <Form.Control type="text" value={this.state.scenario.name} onChange={this.handleName} />
                        </InputGroup>
                        <Form.Check name="scenario-active" checked={this.state.scenario.active} onChange={this.handleIsActive} label="Activer ce scnénario" />
                    </Card.Body>
                    <Card.Footer>
                        <Button type="submit" className="btn-small-margin">Enregistrer</Button>
                    </Card.Footer>
                </Form>
                <Modal show={this.state.modal.show}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.modal.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modal.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.dismissModal}>
                            Fermer
                        </button>
                    </Modal.Footer>
                </Modal>
            </Card>);
        }
        else {
            return "";
        }
    }

}
