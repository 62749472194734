import axios from "axios";
import Cookies from "universal-cookie";

export default class ClientApi {
  url = "https://nas.nantesbikesolutions.com:8082/";
  urlBackUp = "https://failover.nantesbikesolutions.com:8082/";
  //url = "http://localhost:8092/"
  //urlBackUp = "http://localhost:8092/"
  cookies = new Cookies()
  headers = {
    headers: { Authorization: "Basic ".concat(this.cookies.get("token")) }
  }

  async validateUrl() {
    var responseMainURL
    var responseBackupURL

    try {
      responseMainURL = await axios.get(this.url.concat("availability/"))
      if (responseMainURL !== undefined && responseMainURL.status === 200) {
        this.cookies.set("token-url-nbs-admin", this.url);
      }
    }
    catch (error) {
      try {
        responseBackupURL = await axios.get(this.urlBackUp.concat("availability/"))
        if (responseBackupURL !== undefined && responseBackupURL.status === 200) {
          this.cookies.set("token-url-nbs-admin", this.urlBackUp);
        }
      }
      catch (error) { }
    }


    if (responseMainURL === undefined && responseBackupURL === undefined) {
      alert("Pas de serveur de dispo")
    }
  }

  async postAxiosNoBody(url) {
    return await this.postAxiosWithBody(url, {})
  }

  async postAxiosWithBody(url, body) {
    return await axios.post(this.cookies.get("token-url-nbs-admin").concat(url), body, this.headers)
  }

  async postAxiosWithBodyNoHeaders(url, body) {

    return await axios.post(this.cookies.get("token-url-nbs-admin").concat(url), body)
  }

  async getAxios(url) {

    return await axios.get(this.cookies.get("token-url-nbs-admin").concat(url), this.headers)
  }

  async deleteAxios(url) {

    return await axios.delete(this.cookies.get("token-url-nbs-admin").concat(url), this.headers)
  }

  async getAxiosNoHeaders(url) {
    return await axios.get(this.cookies.get("token-url-nbs-admin").concat(url))
  }

  retrievePendingForm(id) {
    return this.getAxios("admin/form/pending/".concat(id));
  }

  retrieveForm(id) {
    return this.getAxios("admin/form/".concat(id));
  }

  retrievePendingForms() {
    return this.getAxios("admin/form/pending/");
  }

  saveCustomer(customer, bike, formId, sendMail) {
    return this.postAxiosWithBody("admin/form/validate/",
      { customer: customer, bike: bike, formId: formId, sendMail: sendMail }
    );
  }

  deleteForm(id) {
    return this.deleteAxios("admin/form/delete/".concat(id))
  }

  login(user, secret) {
    const request = {
      login: user,
      password: secret,
    };

    return this.postAxiosWithBodyNoHeaders("admin/authenticate/", request)
  }

}
