import ClientApi from "./ClientApi";

export default class ClientWebPageAPI extends ClientApi {

    retrieveWebPageManager(idPage) {
        return this.getAxios("admin/webpage/".concat(idPage))
    }

    retrieveMessageForCategory(idCategory, idScenario) {
        return this.getAxios(
            "admin/webpage/message/category/"
                .concat(idCategory)
                .concat("/scenario/")
                .concat(idScenario))
    }

    savePage(request) {
        return this.postAxiosWithBody("admin/save-page/", request);
    }

    savePartner(partner) {
        return this.postAxiosWithBody("admin/webpage/partner/save", partner);
    }

    savePartners(partners) {
        return this.postAxiosWithBody("admin/webpage/partners/save", partners);
    }

    retrievePartners() {
        return this.getAxios("webpage/partner")
    }

    removePartner(partnerId) {
        return this.postAxiosNoBody("admin/webpage/partner/delete/"
                .concat(partnerId))
    }



}