import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import ClientApi from "../swagger-client/ClientApi";

import {
  withRouter
} from "react-router-dom";

class Appointment extends Component {

  clientApi = new ClientApi();

  state = {
    showModal: false
  }
  appointments = this.props.message;

  handleClickOnUpdate = (event) => {
    event.preventDefault();
    this.props.history.push("/update/".concat(this.props.message.form.id))
  }

  handleClickOnDelete = (event) => {
    event.preventDefault();
    this.setState({ showModal: true })

  }

  deleteMessage = () => {
    this.setState({ showModal: true })
    this.clientApi.deleteForm(this.appointments.form.id).then((res) => {
      this.appointments.form = undefined
      this.dismissModal()
    })
  }

  dismissModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const form = this.appointments.form;

    if (form) {
      const date = new Date(this.appointments.form.formSubmissionDate);

      var flag = "";
      if (this.appointments.form.customer != null) {
        flag = (<span class="badge bg-primary text-dark">
          Espace-client
        </span>)
      }
      else if (this.appointments.customers.length > 0) {
        flag = (
          <span class="badge bg-warning text-dark">
            Correspondance {this.appointments.customers.length}
          </span>
        );
      }
      else {
        flag = (
          <span class="badge bg-success text-dark">
            Nouveau client
          </span>
        );
      }

      return (
        <tr>
          <td>
            {form.id}
            <Modal show={this.state.showModal}>
              <Modal.Header>
                <Modal.Title>
                  Cette action est irréversible
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Êtes-vous certain de vouloir supprimer la demande de
                <br />
                {form.genre === 1 ? "M." : "Mme"} {form.surname} {form.name} ?
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary" onClick={this.dismissModal}>
                  Non
                </button>
                <button className="btn btn-danger" onClick={this.deleteMessage}>
                  Supprimer
                </button>
              </Modal.Footer>
            </Modal>
          </td>
          <td>
            <h5>
              {form.genre === 1 ? "M." : "Mme"} {form.surname} {form.name} {flag}
            </h5>
            <p>
              {new Intl.DateTimeFormat("fr-FR", {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }).format(date)}
            </p>
          </td>
          <td>
            <p>
              {form.phoneNumber} {form.mail}
            </p>
            <p>
              {form.addressFirstLine}, {form.zipCode}
              {form.city}
            </p>
          </td>
          <td>
            <p>
              {form.bikeGender} {form.bikeType} {form.bikeBrand} {form.bikeModel}{" "}
              {form.bikeColor}
            </p>
            <p>
              <b>{form.dropInfo}</b>
            </p>
            <p>{form.bikeDysfunctions}</p>
          </td>
          <td>
            <div className="mb-1">
              <button type="button" class="btn btn-primary" onClick={this.handleClickOnUpdate}>
                Vérifier
              </button>
            </div>
            <div className="mb-1">
              <button type="button" class="btn btn-danger" onClick={this.handleClickOnDelete}>
                Supprimer
            </button>
            </div>
          </td>
        </tr>
      );
    }
    else {
      return ""
    }

  }
}
export default withRouter(Appointment)