import React, { Component } from "react";
import {
    withRouter
} from "react-router-dom";
import ClientTemplateAPI from "../../../swagger-client/ClientTemplatesAPI"
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Category from "./Category";
import Modal from "react-bootstrap/Modal";

class Categories extends Component {

    clientApi = new ClientTemplateAPI();

    state = {
        newCategory: {},
        categories: [],
        modal: {
            show: false,
            message: ""
        }
    }

    componentDidMount() {
        this.clientApi.retrieveCategories().then((res) => {
            const categories = res.data;
            this.setState({ categories: categories });
        });
    }

    handleNewCategory = (event) => {
        event.preventDefault();
        this.clientApi.saveMessageTemplateCategory(this.state.newCategory).then(res => {
            this.state.categories.push(res.data)
            this.setState({
                newCategory: {
                    title: "",
                    showButton: false,
                    allowSignature: false,
                    isArticle: false,
                },
                modal: { show: true, message: "Opération réussie" }
            });
        }).catch = (errors) => {
            this.setState({ modal: { show: true, message: errors.toString() } });
        };
    }

    handleTitle = (event) => {
        var newCategory = { title: event.target.value }
        this.setState({ newCategory: newCategory })
    }

    handleIsArticle = (event) => {
        var newCategory = this.state.newCategory
        newCategory.showButton = event.target.value === "false"
        newCategory.isArticle = event.target.value === "true"

        this.setState({ newCategory: newCategory })
    }


    dismissModal = () => {
        this.setState({ modal: { show: false, message: "" } })
    }

    render() {
        const categoryLine = this.state.categories.map((it) => (
            <Category key={it.id} message={it} />
        ));

        return (<div>
            <nav class="navbar navbar-dark bg-orange">
                <div class="container-fluid">
                    <span class="navbar-brand mb-0 h1">
                        <h1>Gestion des catégories de message</h1>
                    </span>
                </div>
            </nav>
            <div className="container">
                <Modal show={this.state.modal.show}>
                    <Modal.Header>
                        <Modal.Title>
                            Résultat de l'action
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modal.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.dismissModal}>
                            Fermer
                        </button>
                    </Modal.Footer>
                </Modal>
                <h2>Créer une nouvelle catégorie</h2>
                <Card style={{ width: '18rem', margin: '1em' }} >
                    <Form onSubmit={this.handleNewCategory}>
                        <Card.Body>
                            <Form.Control type="text" value={this.state.newCategory.title} onChange={this.handleTitle} />
                            <Form.Check type="radio" required name="mailOrArticle" checked={this.state.newCategory.isArticle === false} value={false} onChange={this.handleIsArticle} label="e-mail" />
                            <Form.Check type="radio" required name="mailOrArticle" checked={this.state.newCategory.isArticle === true} value={true} onChange={this.handleIsArticle} label="article" />                        </Card.Body>
                        <Card.Footer>
                            <Button variant="primary" type="submit">Créer</Button>
                        </Card.Footer>
                    </Form>
                </Card>
                <h2>Catégories existantes</h2>
                {categoryLine}
            </div>
        </div>);
    }
}
export default withRouter(Categories);