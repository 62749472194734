import React, { Component } from "react";
import Appointment from "./Appointment";
import ClientApi from "../swagger-client/ClientApi";
import Modal from "react-bootstrap/Modal";
import PDFMaker from "../pdf/PDFMaker";
export default class AppointmentManager extends Component {
  clientApi = new ClientApi();
  state = {
    appointments: [],
    searchId: "",
    showModal: false,
    modalServerError: false,
    searchedForm: {},
  };

  intervalCall = 30000

  handleSearch = (evt) => {
    this.setState({ searchId: evt.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.clientApi.retrieveForm(this.state.searchId).then((res) => {
      this.setState({ showModal: true, searchedForm: res.data });
    });
  };

  hideModal = (event) => {
    this.setState({ showModal: false, modalServerError: false });
  };

  createPdf = (event) => {
    new PDFMaker().createPdfForFolder(
      this.state.searchedForm.id,
      new Date(this.state.searchedForm.formSubmissionDate),
      this.state.searchedForm.folder,
      this.state.searchedForm.bikeDysfunctions,
      this.state.searchedForm.dropInfo,
    );
  };

  componentDidMount() {

    this.clientApi.retrievePendingForms()
      .then((res) => {
        this.setState({ appointments: res.data });
      })
      .catch(error => {
        this.setState({ showModal: false, modalServerError: true });
      });

    this.myInterval = setInterval(async () => {
      this.clientApi.retrievePendingForms()
        .then((res) => {
          this.setState({ appointments: res.data });
        })
        .catch(error => {
          this.setState({ showModal: false, modalServerError: true });
        });;
    }, this.intervalCall);

  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const appointmentView = this.state.appointments.map((it) => (
      <Appointment key={it.id} message={it} />
    ));

    var pdfButton = "";

    if (this.state.searchedForm.archived) {
      pdfButton = <button className="btn btn-danger" onClick={this.createPdf}>
        Recréer PDF
      </button>
    }

    if (this.state.searchedForm.formSubmissionDate !== undefined) {
      var submissionDate = new Intl.DateTimeFormat("fr-FR", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(this.state.searchedForm.formSubmissionDate))
    }

    return (
      <div>
        <Modal show={this.state.modalServerError} variant="danger">
          <Modal.Header>
            <Modal.Title>Le serveur ne répond plus</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Le serveur n'envoie plus de données!
            </p>
          </Modal.Body>
          <Modal.Footer>
            {pdfButton}
            <button className="btn btn-primary" onClick={this.hideModal}>
              Fermer
            </button>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title># {this.state.searchedForm.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Demande effectuée le {submissionDate}
            </p>
            <p>
              {this.state.searchedForm.genre === 1 ? "M." : "Mme"}{" "}
              {this.state.searchedForm.name} {this.state.searchedForm.surname}{" "}
              <br />
            </p>
            <p>
              {this.state.searchedForm.phoneNumber}{" "}
              {this.state.searchedForm.mail}
            </p>
            <p>
              {this.state.searchedForm.addressFirstLine}
              <br />
              {this.state.searchedForm.zipCode} {this.state.searchedForm.city}
            </p>
            <p>
              {this.state.searchedForm.bikeGender}{" "}
              {this.state.searchedForm.bikeType}{" "}
              {this.state.searchedForm.bikeBrand}{" "}
              {this.state.searchedForm.bikeModel}{" "}
              {this.state.searchedForm.bikeColor}
            </p>
            <p>{this.state.searchedForm.bikeDysfunctions}</p>
            <p>
              <b>
                {this.state.searchedForm.archived
                  ? " a été traitée "
                  : " est en cours de traitement"}
              </b>
            </p>
          </Modal.Body>
          <Modal.Footer>
            {pdfButton}
            <button className="btn btn-primary" onClick={this.hideModal}>
              Fermer
            </button>
          </Modal.Footer>
        </Modal>
        <nav class="navbar navbar-dark bg-orange">
          <div class="container-fluid">
            <span class="navbar-brand mb-0 h1">
              <h1>Rendez-vous à valider</h1>
            </span>
            <div class="float-end">
              <form onSubmit={this.handleSubmit}>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    required
                    placeholder="Numéro de la demande"
                    value={this.state.searchId}
                    onChange={this.handleSearch}
                  ></input>
                  <input
                    type="submit"
                    class="btn btn-primary"
                    id="button-addon2"
                    value="Rechercher"
                  />
                </div>
              </form>
            </div>
          </div>
        </nav>

        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th># Demande</th>
              <th>Client</th>
              <th>Adresse</th>
              <th>Vélo</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{appointmentView}</tbody>
        </table>
      </div>
    );
  }
}
