import { withRouter } from "react-router";
import { Container } from "react-bootstrap";
import { Component } from "react";
import Scenario from "./Scenario";
import ClientScenarii from "../../swagger-client/ClientScenarii"

class Scenarii extends Component {

    clientApi = new ClientScenarii();

    state = {
        scenarii : []
    }

    componentDidMount() {
        this.clientApi.retrieveScenarii().then(res => {
            this.setState({scenarii : res.data});
        })
        .catch (error => {
            alert ("Erreur retrieveScenarii")
        })
    }

    render() {
        const scenario = this.state.scenarii.map((it) => (
            <Scenario key={it.id} message={it} />
        ));

        return (<div>
            <nav class="navbar navbar-dark bg-orange">
                <Container fluid="lg">
                    <span class="navbar-brand mb-0 h1">
                        <h1>Gestion des Scenarii</h1>
                    </span>
                </Container>
            </nav>

            {scenario}
        </div>)
    }
}
export default withRouter(Scenarii)