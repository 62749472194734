import React, { Component } from "react";
import ClientTemplateAPI from "../../../swagger-client/ClientTemplatesAPI";
import {
    withRouter
} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class Category extends Component {
    clientApi = new ClientTemplateAPI();

    state = {
        category: this.props.message,
        modal: {
            show: false,
            message: "",
            title: "",
            modification: false,
        }
    }

    handleSubmitCategory = (event) => {
        event.preventDefault();
        this.clientApi.saveMessageTemplateCategory(this.state.category).then(res => {
            this.setState({ modal: { show: true, message: "Opération réussie" } });
        }).catch = (errors) => {
            this.setState({ modal: { show: true, message: errors.toString() } });
        };
    }

    handleTitle = (event) => {
        var category = this.state.category
        category.title = event.target.value

        this.setState({ category: category })
    }

    handleIsArticle = (event) => {
        var category = this.state.category
        category.showButton = event.target.value === "false"
        category.isArticle = event.target.value === "true"

        this.setState({ category: category })
    }

    handleDelete = () => {
        this.clientApi.deleteCategory(this.state.category.id)
        this.setState({ category: undefined })
        this.dismissModal()
    }

    showDeleteModal = () => {
        this.setState({
            modal: {
                show: true,
                message: "Etes-vous sûr de vouloir effectuer cette action?",
                title: "Suppression définitive",
                modification: true
            }
        })
    }

    dismissModal = () => {
        this.setState({ modal: { show: false, message: "", title: "", modification: false } })
    }

    render() {
        if (this.state.category !== undefined) {
            var DeleteButton = this.state.category.isDeletable ?
                <Button type="button" variant="danger" className="btn-small-margin" onClick={this.showDeleteModal}>Supprimer</Button> : ""

            var modificationButton = this.state.modal.modification ?
                <Button type="button" variant="danger" onClick={this.handleDelete}>Supprimer</Button> : ""

            return (<Card style={{ width: '18rem', display: 'inline-block', margin: '1em' }}>
                <Form onSubmit={this.handleSubmitCategory}>
                    <Card.Body>
                        <Form.Control type="text" value={this.state.category.title} onChange={this.handleTitle} />
                        <Form.Check type="radio" required name="mailOrArticle" checked={this.state.category.isArticle === false} value={false} onChange={this.handleIsArticle} label="e-mail" />
                        <Form.Check type="radio" required name="mailOrArticle" checked={this.state.category.isArticle === true} value={true} onChange={this.handleIsArticle} label="article" />
                    </Card.Body>
                    <Card.Footer>
                        <Button type="submit" className="btn-small-margin">Enregistrer</Button>
                        {DeleteButton}
                    </Card.Footer>
                </Form>
                <Modal show={this.state.modal.show}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.modal.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modal.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.dismissModal}>
                            Fermer
                    </button>
                        {modificationButton}
                    </Modal.Footer>
                </Modal>
            </Card>);
        }
        else {
            return "";
        }
    }
}
export default withRouter(Category)