import { Component } from "react";
import { jsPDF } from "jspdf";
import PageOneBase64 from "../pdf/PageOneBase64"
import PageTwoBase64 from "../pdf/PageTwoBase64"

export default class PDFMaker extends Component {
  x = 100;
  doc = new jsPDF("p", "px", "a4"); 

  pageOneB64 = new PageOneBase64().pageOneB64;
  pageTwoB64 = new PageTwoBase64().pageTwoB64;

  async createPdfForFolder(idForm, submissionDate, folder, dysfunctions, dropInfo) {

    var dateSub = new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(submissionDate)

    this.createFirstPage(idForm, dateSub, folder.customer, folder.bike, folder.id, dysfunctions, dropInfo)
    this.createASticker(folder, dateSub, 10, 10)
    
    // page blanche verso
    this.doc.addPage()

    // page fiche atelier recto
    this.doc.addPage()
    this.doc.addImage(this.pageOneB64, "PNG", 0, 0, 447, 633)
    this.createASticker(folder, dateSub, 8, 7)

    // sitcker B
    this.createBSticker(folder, 170, 7)

    // sticker C
    this.createCSticker(folder, 8, 81)

    // fiche atelier verso
    this.doc.addPage()
    this.doc.addImage(this.pageTwoB64, "PNG", 0, 0, 447, 633)

    this.doc.save(
      "FORMULAIRE-"
        .concat(idForm)
        .concat(folder.customer.name)
        .concat(folder.customer.surname)
        .concat("_")
        .concat(folder.bike.brand)
        .concat("_")
        .concat(folder.bike.model)
        .concat("_")
        .concat(folder.bike.color)
        .concat(".pdf")
    );
  }

  createFirstPage(idForm, dateSub, customer, bike, idFolder, dysfunctions, dropInfo) {
    this.doc.line(20, 20, 60, 20)

    this.doc.setFont(undefined, 'bold')
    this.doc.text("Demande ".concat(idForm), 20, this.x); //string, this.x-position, y-position
    this.doc.text(dateSub, 200, this.x); //TODO à revoir pour la date

    this.doc.setFont(undefined, 'normal')
    this.doc.text(customer.name.concat(" ").concat(customer.surname), 20, this.x + 20);
    this.doc.text("Dossier : ".concat(idFolder), 200, this.x + 20);
    if (dropInfo !== null) {
      this.doc.text(dropInfo, 200, this.x + 40);
    }

    this.doc.text(customer.phoneNumber, 20, this.x + 40);
    this.doc.text(customer.mail, 20, this.x + 60);
    if (customer.addressFirstLine !== null) {
      this.doc.text(customer.addressFirstLine, 20, this.x + 80);
    }

    if (customer.zipCode !== null && customer.city !== null) {
      this.doc.text(customer.zipCode.concat(" ").concat(customer.city), 20, this.x + 100);
    }

    this.doc.setFont(undefined, 'bold')
    this.doc.text(
      bike.hasAlreadyCome
        ? "Le vélo est déjà passé à l'atelier"
        : "Le vélo n'est jamais passé à l'atelier",
      20,
      this.x + 140
    );

    this.doc.setFont(undefined, 'normal')
    this.doc.text(bike.practice + " " + bike.gender + " " + bike.color, 20, this.x + 160);
    this.doc.text(bike.brand.concat(" ").concat(bike.model), 20, this.x + 180);

    this.doc.setFont(undefined, 'bold');
    this.doc.text("Dysfonctionnements constatés par le client : ", 20, this.x + 220);
    this.doc.setFont(undefined, 'normal');

    var lines = this.doc.setFontSize(12)
      .splitTextToSize(dysfunctions, 300);
    this.doc.text(lines, 20, this.x + 240);
  }

  createASticker(folder, dateSub, x, y) {
    this.doc.setFontSize(10)
    this.doc.setFillColor(255, 255, 255)
    this.doc.rect(x, y, 123, 70, 'FD')

    this.doc.rect(x + 22, y+4, 32, 11);
    this.doc.text("NBS ", x + 5, y + 13);
    this.doc.setFontSize(12)
    this.doc.setFont(undefined, 'bold');
    this.doc.text(folder.customer.id.toString(), x + 23, y + 13);
    this.doc.setFontSize(10)
    this.doc.setFont(undefined, 'normal');
    this.doc.text(" ID G. ".concat(folder.customer.idGestan), x + 60, y + 13);

    this.doc.rect(x + 2, y + 15, 119, 23);
    this.doc.setFontSize(14)
    this.doc.setFont(undefined, 'bold');
    var surname = folder.customer.surname.toUpperCase()
    var name = folder.customer.name.charAt(0).toUpperCase().concat(folder.customer.name.slice(1))
    this.doc.text(surname, x + 5, y + 25);
    this.doc.text(name, x + 5, y + 35);

    this.doc.setFont(undefined, 'normal');
    this.doc.setFontSize(10)
    this.doc.text(folder.customer.phoneNumber, x + 5, y + 45);
    this.doc.text(dateSub, x + 5, y + 63);
  }

  createBSticker(folder, x = 170, y = 8) {
    this.doc.setFillColor(255, 255, 255);
    this.doc.rect(x, y, 123, 70, 'FD');

    this.doc.setFont(undefined, 'bold')
    this.doc.text(folder.bike.type, x + 5, y + 12);
    this.doc.text(folder.bike.id.toString(), x + 85, y + 12);
    this.doc.setFont(undefined, 'normal')

    this.doc.text(folder.bike.practice, x + 5, 30);
    this.doc.rect(x + 35, y + 14, 30, 10)
    this.doc.text(folder.bike.gender, x + 38, y + 22);
    this.doc.text(folder.bike.ebike ? "Elec" : "", x + 85, y + 22);

    this.doc.rect(x + 2, y + 24, 119, 10)
    this.doc.text(folder.bike.color.concat(" ").concat(folder.bike.brand), x + 5, y + 32);
    this.doc.text(folder.bike.distinction ? folder.bike.distinction : "", x + 5, y + 32);
    this.doc.text(folder.bike.park ? folder.bike.park : "", x + 85, y + 42);
    this.doc.text(folder.bike.model, x + 5, y + 52);
    this.doc.line(x, y + 55, x + 123, y + 55)
    this.doc.text(folder.customer.name.concat(" ")
      .concat(folder.customer.surname)
      .concat(" ")
      .concat(folder.customer.id),
      x + 10, y + 63);
  }

  createCSticker(folder, x , y ) {
    this.doc.setFillColor(255, 255, 255);
    this.doc.rect(x, y, 123, 70, 'FD');

    this.doc.text("N° Dossier : ", x + 11, y + 17)
    this.doc.setFontSize(15)
    this.doc.setFont(undefined, 'bold');
    this.doc.text(folder.id.toString(), x + 61, y + 17);
    this.doc.setFontSize(10)
    this.doc.setFont(undefined, 'normal');

    this.doc.text("N° Client : ", x + 11, y + 37)
    this.doc.text(folder.customer.id.toString(), x + 61, y + 37);
    this.doc.text("N° Vélo : ", x + 11, y + 47)
    this.doc.text(folder.bike.id.toString(), x + 61, y + 47);
  }

  render() {
    return null;
  }
}
