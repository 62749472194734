
import { Component } from "react";
import Login from "./components/Login";
import AppointmentManager from "./components/AppointmentManager";
import Update from "./components/Update";
import Menu from "./components/menu/Menu";
import Templates from "./components/templates/Templates";
import SaveTemplate from "./components/templates/SaveTemplate"
import Cookies from "universal-cookie";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Categories from "./components/templates/categories/Categories";
import Signatures from "./components/templates/signatures/Signatures";
import Partners from "./components/website/Partners";
import PageManager from "./components/website/PageManager";
import Scenarii from "./components/scenario/Scenarii";
import Sales from "./components/website/Sales";
import ClientApi from "./swagger-client/ClientApi";

export default class App extends Component {
  cookies = new Cookies()
  swagger = new ClientApi()
  token = this.cookies.get("token");

  componentDidMount() {
    this.swagger.validateUrl()
  }

  render() {
    if (!this.token) {
      return (
        <Login />
      );
    } else {
      return (
          <Router>
            <Menu />
            <div className="pva-body">
            <Switch>
              <Route path="/update/:id" component={Update} />
              <Route exact path="/templates" component={Templates} />
              <Route exact path="/templates/new" component={SaveTemplate} />
              <Route exact path="/templates/new/:categoryId/:scenarioId" component={SaveTemplate} />
              <Route exact path="/template/update/:id" component={SaveTemplate} />
              <Route path="/templates/categories" component={Categories} />
              <Route path="/templates/category/:id" component={Templates} />
              <Route path="/templates/signatures" component={Signatures} />
              <Route exact path="/website/partners" component={Partners} />
              <Route exact path="/website/sales" component={Sales} />
              <Route path="/website/:pageId" component={PageManager} />
              <Route path="/scenarii" component={Scenarii} />
              <Route path="/">
                <AppointmentManager />
              </Route>
            </Switch>
            </div>
          </Router>
      );
    }
  }
}
