import React, { Component } from "react";
import {
    withRouter
} from "react-router-dom";
import TemplatesCategory from "./TemplatesCategory";
import ClientTemplateAPI from "../../swagger-client/ClientTemplatesAPI";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup"

class Templates extends Component {

    clientApi = new ClientTemplateAPI();

    state = { filterCategory: undefined, templatesResponse: [], templatesResponseToKeep: [] }


    componentDidMount() {

        this.clientApi.retrieveMessageTemplates().then((res) => {
            this.setState({ templatesResponse: res.data, templatesResponseToKeep: res.data });
        });
    }

    handleNewTemplate = (event) => {
        this.props.history.push("/templates/new")
    }

    handleFilterCategory = (event) => {
        if (event.target.value !== "ALL") {
            this.state.templatesResponseToKeep.forEach(response => {
                if (response.category.id === parseInt(event.target.value)) {
                    this.setState({ templatesResponse: [response] })
                }
            })
        }
        else {
            this.setState({ templatesResponse: this.state.templatesResponseToKeep })
        }
    }

    render() {

        const templateLine = this.state.templatesResponse.map((it) => (
            <TemplatesCategory key={it.category.id} message={it} />
        ));

        var selectCategory = [<option defaultValue value="ALL">- Selectionner -</option>]
        this.state.templatesResponseToKeep.forEach(response => {
            selectCategory.push(<option value={response.category.id}>
                {response.category.title} -- {response.templates.length} {response.templates.length === 1 ? "message" : "messages"}
            </option>)
        })

        return (<div>
            <nav class="navbar navbar-dark bg-orange">
                <div class="container-fluid">
                    <span class="navbar-brand mb-0 h1">
                        <h1>Gestion des modèles de message</h1>
                    </span>
                    <div className="float-end">
                        <button className="btn btn-primary" onClick={this.handleNewTemplate}>
                            Nouveau
                        </button>
                    </div>
                </div>
            </nav>
            <div className="container-md">
                <Card>
                    <Card.Header>Filtres</Card.Header>
                    <Card.Body>
                        <div>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Catégorie</InputGroup.Text>

                                <select
                                    required="required"
                                    className="form-control form-select required"
                                    aria-label="Default select"
                                    value={this.state.filterCategory}
                                    onChange={this.handleFilterCategory}
                                >
                                    {selectCategory}
                                </select>
                            </InputGroup>
                        </div>
                    </Card.Body>
                </Card>
            </div>

            { templateLine}
        </div >);
    }
}
export default withRouter(Templates);