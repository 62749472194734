import React, { Component } from "react";
import { Badge } from "react-bootstrap";

import {
    withRouter
} from "react-router-dom";
import TemplateLine from "./TemplateLine";

class TemplateCategory extends Component {
    state = this.props.message;

    render() {
        const templateLine = this.state.templates.map((it) => (
            <TemplateLine key={it.id} message={it} />
        ));

        return <div>
            <br />
            <h2>
                <Badge className="bg-secondary">id : {this.state.category.id}</Badge>{" "}
                {this.state.category.isArticle ? <Badge className="bg-dark" variant="dark">Article</Badge> : ""}
                {this.state.category.showButton ? <Badge className="bg-info" variant="light">E-Mail</Badge> : ""}
                {" "}{this.state.category.title}
                
            </h2>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Scenario</th>
                        <th>Titre</th>
                        <th>Message</th>
                        <th>Emetteur</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>{templateLine}</tbody>
            </table>

        </div>
    }
}
export default withRouter(TemplateCategory)