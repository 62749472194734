import React, { Component } from "react";
import { Container, Form, Button, Card, Row, Col, Modal } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LogoVertical from "../../resources/vertical.png"
import ClientSalesApi from "../../swagger-client/ClientSalesApi";
import Compress from "react-image-file-resizer";

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '',
    padding: 1,
    width: "100%"
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 1,
    margin: "1 2 2 1",

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '',

    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class Sales extends Component {

    clientApi = new ClientSalesApi()

    state = {
        dynamicPage: {
            headerTitle: "",
            headerContent: "",
        },
        newsale: {
            sold: false,
            creationDate: new Date(),
        },
        sales: [],
        show: false,
    }

    componentDidMount() {
        var stateTemp = this.state
        this.clientApi.retrieveSales().then(res => {
            stateTemp.sales = res.data
            this.setState(stateTemp)
        })

        this.clientApi.retriveDynamicPage("SALE").then(res => {
            stateTemp.dynamicPage = res.data[0]
            this.setState(stateTemp)
        })
    }

    createSaleSubmit = (event) => {
        event.preventDefault();

        this.state.newsale.position = this.state.sales.length;

        this.clientApi.saveSale(this.state.newsale)
            .then(res => {
                this.state.sales.push(res.data)
                this.setState({
                    newsale: {
                        sold: false,
                        creationDate: new Date(),
                    },
                    sales: this.state.sales,
                    show: false
                })
            })
            .catch(error => {
                alert(error)
            });
    }

    handleName = (event) => {
        var actualState = this.state;
        actualState.newsale.name = event.target.value;
        this.setState(actualState)
    }

    handleDescription = (event) => {
        var actualState = this.state;
        actualState.newsale.description = event.target.value;
        this.setState(actualState)
    }

    handlePrice = (event) => {
        var actualState = this.state;
        actualState.newsale.priceShown = event.target.value;
        this.setState(actualState)
    }

    handleCost = (event) => {
        var actualState = this.state;
        actualState.newsale.cost = event.target.cost;
        this.setState(actualState)
    }

    handleCreationDate = (event) => {
        var actualState = this.state;
        actualState.newsale.creationDate = event.target.value;
        this.setState(actualState)
    }

    handleSaleDate = (event) => {
        var actualState = this.state;
        actualState.newsale.saleDate = event.target.cost;
        this.setState(actualState)
    }

    handleSold = (event) => {
        var actualState = this.state;
        actualState.newsale.sold = event.target.checked;
        this.setState(actualState)
    }


    //setFile(files) {
    //    var actualState = this.state;
    //    actualState.newsale.picture = files.base64;
    //
    //    this.setState(actualState)
    //}


    onFileResize = e => {
        const file = e.target.files[0];

        Compress.imageFileResizer(
            file, // the file from input
            480, // width
            480, // height
            "JPEG", // compress format WEBP, JPEG, PNG
            70, // quality
            0, // rotation
            (uri) => {
                console.log(uri);
                var actualState = this.state;
                actualState.newsale.picture = uri;

                this.setState(actualState)
                // You upload logic goes here
            },
            "base64" // blob or base64 default base64
        );
    }


    handleNameIndexed = (index, event) => {
        var sale = this.state.sales[index];
        sale.name = event.target.value;
        this.setState(this.state);

    }

    handleDescriptionIndexed = (index, event) => {
        var sale = this.state.sales[index];
        sale.description = event.target.value;
        this.setState(this.state);

    }

    handlePriceIndexed = (index, event) => {
        var sale = this.state.sales[index];
        sale.priceShown = event.target.value;
        this.setState(this.state);
    }

    handleCostIndexed = (index, event) => {
        var sale = this.state.sales[index];
        sale.cost = event.target.value;
        this.setState(this.state);
    }

    handleIsSoldIndexed = (index, event) => {
        var sale = this.state.sales[index];
        sale.sold = event.target.checked;
        this.setState(this.state);
    }

    //handleIndexedFile = (index, files) => {
    //    var sale = this.state.sales[index];
    //    sale.picture = files.base64;
    //    this.setState(this.state);
    //    this.saveSale();
    //}
    handleIndexedFile = (index, e) => {
        const file = e.target.files[0];

        Compress.imageFileResizer(
            file, // the file from input
            800, // width
            800, // height
            "JPEG", // compress format WEBP, JPEG, PNG
            70, // quality
            0, // rotation
            (uri) => {
                console.log(uri);
                var sale = this.state.sales[index];
                sale.picture = uri;
                this.setState(this.state);
                this.saveSale();
                // You upload logic goes here
            },
            "base64" // blob or base64 default base64
        );
    }

    handleCreateDateIndexed = (index, event) => {
        var sale = this.state.sales[index];
        sale.creationDate = event.target.value;
        this.setState(this.state);
    }

    handleSaleDateIndexed = (index, event) => {
        var sale = this.state.sales[index];
        sale.saleDate = event.target.value;
        this.setState(this.state);
    }

    saveSale = () => {
        this.clientApi.saveSales(this.state.sales)
            .then(res => {

            })
            .catch(error => {
                alert(error)
            })
    }

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.sales,
            result.source.index,
            result.destination.index
        );


        items.forEach((sale, index) => {
            sale.position = index
        })

        this.setState({
            sales: items
        });

        this.clientApi.saveSales(items)
            .then(res => {

            })
            .catch(error => {
                alert(error)
            })

    }

    showModal = () => {
        this.setState({ show: true })
    }

    hideModal = () => {
        this.setState({
            newsale: {
                sold: false,
                creationDate: new Date(),
            }, show: false
        })
    }

    deleteSale = (idSale, index, event) => {
        this.clientApi.removeSale(idSale)
            .then(res => {
                this.state.sales.splice(index, 1);
                this.setState(this.state);
            })
            .catch(error => {
                alert(error);
            })
    }

    saveIntroductionSales = () => {
        var dynamicPage = this.state.dynamicPage
        dynamicPage.pageIndex = "SALE"


        this.clientApi.saveDynamicPage(this.state.dynamicPage).then(res => {
            var actualState = this.state;
            actualState.dynamicPage = res.data
            this.setState()
        })
    }

    handleTitleDynamicPage = (event) => {
        var actualState = this.state;
        actualState.dynamicPage.headerTitle = event.target.value;
        this.setState(actualState)
    }

    handleContentDinamycPage = (event) => {
        var actualState = this.state;
        actualState.dynamicPage.headerContent = event.target.value;
        this.setState(actualState)
    }

    render() {
        return (<div>
            <nav className="navbar navbar-dark bg-orange">
                <div className="container-fluid">
                    <span className="navbar-brand mb-0 h1">
                        <h1>
                            Gestion des ventes
                        </h1>
                    </span>
                    <Button variant="primary" type="button" onClick={this.showModal} >Nouveau</Button>
                </div>
            </nav>
            <Modal show={this.state.show} onHide={this.hideModal} centered size="lg" >
                <Modal.Header closeButton closeLabel="Fermer">
                    <Modal.Title>Création d'une vente</Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.createSaleSubmit}>
                    <Modal.Body>
                        <Card>
                            <Card.Header>
                                <Form.Label>
                                    <b>Titre de l'élément à vendre</b>
                                </Form.Label>
                                <Form.Control value={this.state.newsale.name} onChange={this.handleName} lg></Form.Control>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <img src={this.state.newsale.picture} className="img-sale" />

                                        <input
                                            type="file"
                                            id="file"
                                            accept="image/*"
                                            onChange={this.onFileResize}
                                        />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label>
                                                    <b>Description</b>
                                                </Form.Label>
                                                <Form.Control as="textarea" rows={6} value={this.state.newsale.description} onChange={this.handleDescription}></Form.Control>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col>
                                        <Form.Label>
                                            <b>Prix de vente</b>
                                        </Form.Label>
                                        <Form.Control value={this.state.newsale.priceShown} onChange={this.handlePrice}></Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            Coût (libelle à définir)
                                        </Form.Label>
                                        <Form.Control value={this.state.newsale.cost} onChange={this.handleCost}></Form.Control>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>
                                            Date de création
                                        </Form.Label>
                                        <Form.Control value={new Date(this.state.newsale.creationDate).toISOString().substring(0, 10)} type="Date" onChange={this.handleCreationDate}></Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            Date de vente
                                        </Form.Label>
                                        <Form.Control value={this.state.newsale.saleDate != null ? new Date(this.state.newsale.saleDate).toISOString().substring(0, 10) : null} type="Date" onChange={this.handleSaleDate}></Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            est vendu
                                        </Form.Label>
                                        <Form.Check checked={this.state.newsale.sold} onChange={this.handleIsSold}></Form.Check>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">Ajouter</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Container>
                <h1>Introduction</h1>

                <Form.Label>
                    Titre
                </Form.Label>
                <Form.Control
                    value={this.state?.dynamicPage?.headerTitle}
                    onChange={this.handleTitleDynamicPage}
                />

                <Form.Label>
                    Description
                </Form.Label>
                <Form.Control
                    as="textarea"
                    rows={6}
                    value={this.state?.dynamicPage?.headerContent}
                    onChange={this.handleContentDinamycPage}
                >
                </Form.Control>
                <br />
                <Button variant="primary" type="button" onClick={this.saveIntroductionSales} >Enregistrer</Button>

                <h1>Organisation de la page</h1>

                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.sales.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <Card >
                                                    <Card.Header>
                                                        <Row>
                                                            <Col xs="1">
                                                                <img src={LogoVertical} alt="<>" />
                                                            </Col>
                                                            <Col xs="9">
                                                                <Form.Control
                                                                    value={item.name}
                                                                    onChange={(event) => this.handleNameIndexed(index, event)}
                                                                    onBlur={this.saveSale} />
                                                            </Col>
                                                            <Col>
                                                                <Button type="button" variant="danger" className="float-end" onClick={(event) => this.deleteSale(item.id, index, event)} >Supprimer</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs="4">
                                                                <img alt={item.name} src={item.picture} className="img-sale" />
                                                                <input
                                                                    type="file"
                                                                    id="file"
                                                                    accept="image/*"
                                                                    onChange={(event) => this.handleIndexedFile(index, event)}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Label>
                                                                            Description
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={6}
                                                                            value={item.description}
                                                                            onChange={(event) => this.handleDescriptionIndexed(index, event)}
                                                                            onBlur={this.saveSale}>
                                                                        </Form.Control>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label>
                                                                    <b>Prix de vente</b>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    value={item.priceShown}
                                                                    onChange={(event) => this.handlePriceIndexed(index, event)}
                                                                    onBlur={this.saveSale}></Form.Control>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label>
                                                                    Coût (libelle à définir)
                                                                </Form.Label>
                                                                <Form.Control
                                                                    value={item.cost}
                                                                    onChange={(event) => this.handleCostIndexed(index, event)}
                                                                    onBlur={this.saveSale}></Form.Control>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label>
                                                                    Date de création
                                                                </Form.Label>
                                                                <Form.Control
                                                                    value={new Date(item.creationDate).toISOString().substring(0, 10)}
                                                                    type="date"
                                                                    onChange={(event) => this.handleCreateDateIndexed(index, event)}
                                                                    onBlur={this.saveSale}></Form.Control>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label>
                                                                    Date de vente
                                                                </Form.Label>
                                                                <Form.Control
                                                                    value={item.saleDate != null ? new Date(item.saleDate).toISOString().substring(0, 10) : null}
                                                                    type="date"
                                                                    onChange={(event) => this.handleSaleDateIndexed(index, event)}
                                                                    onBlur={this.saveSale}></Form.Control>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label>
                                                                    est vendu
                                                                </Form.Label>
                                                                <Form.Check
                                                                    checked={item.sold}
                                                                    onChange={(event) => this.handleIsSoldIndexed(index, event)}
                                                                    onBlur={this.saveSale}></Form.Check>
                                                            </Col>
                                                        </Row>
                                                    </Card.Footer>
                                                </Card>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>
        </div>)
    }
}
export default Sales