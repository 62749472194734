import ClientApi from "./ClientApi";

export default class ClientScenariiAPI extends ClientApi {
    retrieveScenarii() {
        return this.getAxios("admin/scenarii")
    }

    saveScenario(scenario) {
        return this.postAxiosWithBody("admin/scenario/save", scenario)
    }
}
