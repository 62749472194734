import React, { Component } from "react";
import {
    withRouter,
    NavLink,
} from "react-router-dom";
import LogoForm from "../../resources/008-sign-form-1.svg"
import LogoTemplate from "../../resources/003-web-design-1.svg"
import LogoSignature from "../../resources/010-signature-1.svg"
import LogoWebSite from "../../resources/007-responsive-1.svg"
import LogoCategory from "../../resources/004-checklist.svg"
import LogoScenarii from "../../resources/002-data-collection.svg"
import HandShake from "../../resources/handshake.svg"
import SaleIcon from "../../resources/sale.svg"
import ClientScenariiAPI from "../../swagger-client/ClientScenarii";
import { Badge } from "react-bootstrap";

class Menu extends Component {

    clientScenarii = new ClientScenariiAPI();

    state = { scenarii: [] }

    componentDidMount() {
        this.clientScenarii.retrieveScenarii()
            .then(res => {
                this.setState({ scenarii: res.data })
            })
            .catch(error => { alert(error.message) })

    }


    render() {

        var urlWebSite = "/website/"

        this.state.scenarii.forEach(scenario => {
            if (scenario.active) {
                urlWebSite = urlWebSite.concat(scenario.id)
            }
        })

        const menuScenarii = this.state.scenarii.map(scenario => (
            <li>
                <NavLink exact to={"/website/".concat(scenario.id)} activeClassName="menu-active">
                    <div className="float-start small-margin-right">
                        {scenario.id}:
                    </div>
                    {scenario.name} {scenario.active ? <Badge>En cours</Badge> : ""}
                </NavLink>
            </li>
        ))

        return (<div className="left-menu bg-primary-nbs">
            <ul>
                <li>
                    <NavLink exact to="/" activeClassName="menu-active">
                        <div className="float-start small-margin-right">
                            <img src={LogoForm} alt="" width="28px" />
                        </div>
                        Preinscriptions
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/templates" activeClassName="menu-active">
                        <div className="float-start small-margin-right">
                            <img src={LogoTemplate} alt="" width="28px" />
                        </div>
                        Modèles de Message
                    </NavLink>
                    <ul>
                        <li>
                            <NavLink exact to="/templates/categories" activeClassName="menu-active">
                                <div className="float-start small-margin-right">
                                    <img src={LogoCategory} alt="" width="28px" />
                                </div>
                                Catégories
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to="/templates/signatures" activeClassName="menu-active">
                                <div className="float-start small-margin-right">
                                    <img src={LogoSignature} alt="" width="28px" />
                                </div>
                                Signatures
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink exact to={urlWebSite} activeClassName="menu-active">
                        <div className="float-start small-margin-right">
                            <img src={LogoWebSite} alt="" width="28px" />
                        </div>
                        Le site
                    </NavLink>
                    <ul>
                        {menuScenarii}
                        <li></li>
                        <li>
                            <NavLink exact to={"/website/partners"} activeClassName="menu-active">
                                <div className="float-start small-margin-right">
                                    <img src={HandShake} alt="" width="28px" />
                                </div>
                                Partenaires
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to={"/website/sales"} activeClassName="menu-active">
                                <div className="float-start small-margin-right">
                                    <img src={SaleIcon} alt="" width="28px" />
                                </div>
                                Ventes
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink exact to="/scenarii" activeClassName="menu-active">
                        <div className="float-start small-margin-right">
                            <img src={LogoScenarii} alt="" width="28px" />
                        </div>
                        Scenarii
                    </NavLink>
                </li>
            </ul>
        </div>)
    }
}
export default withRouter(Menu);